///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Wrapper */

	#wrapper {
		@include vendor('display', 'flex');
		@include vendor('flex-direction', 'row');
		@include vendor('transition', 'opacity #{_duration(menu)} ease');
		margin: 0 auto;
		max-width: 100%;
		opacity: 1;
		padding: _size(section-spacing);
		width: 90em;

		@include breakpoint(medium) {
			padding: _size(section-spacing-small);
			flex-direction: column-reverse;
		}

		body.single & {
			display: block;
		}
	}