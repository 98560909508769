///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Intro */

	#intro {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;


		header {
			text-align: center;

			h2 {
				font-size: 1.5em;
				font-weight: _font(weight-heading-extrabold);
				
			}

			p {
				font-size: 0.8em;
			}
		}



		@include breakpoint(small) {
			margin: 0 0 _size(section-spacing-small) 0;
			padding: 1.25em 0;

			> :last-child {
				margin-bottom: 0;
			}

			header {
				h2 {
					font-size: 1.25em;
				}

				> :last-child {
					margin-bottom: 0;
				}
			}
		}
	}
