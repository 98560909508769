.skilltag {

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        min-width: 84px;
        gap: 10px;
      
        @media screen and (min-width: 768px) {
          margin-top: 20px;
        }
      
        .text {
          background-color:_palette(fg-bold);
          color: white;
          padding: 4px 29px;
          border-radius: 10px;
          font-size: 12px;
          text-align: center;
        }
      }
      
