///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Main */
body {
	padding-top: 0;
	@include breakpoint(small) {
		padding-top: 1em;
	}
}

	#main {
		@include vendor('flex-grow', '1');
		-ms-flex: 1;
		width: 100%;
		padding-left: (22em + _size(section-spacing));

		@include breakpoint(medium) {
			margin: _size(section-spacing) 0 0 0;
			min-width: 0;
			padding: _size(section-spacing) 0 0 0;
			width: 100%;
		}
	}