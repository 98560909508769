///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Sidebar */

	#sidebar {
		position: fixed;
		margin-right: _size(section-spacing);
		padding: _size(section-spacing) 0 0 0;
		min-width: 22em;
		width: 22em;
		

		> :last-child {
			border-top: solid 1px _palette(border);
			border-bottom: solid 1px _palette(border);
			margin: (_size(section-spacing)*0.2) 0 0 0;
			padding: (_size(section-spacing)*0.5) 0;
		}

		> :first-child {
			border-top: 0;
			margin-top: 0;
			padding-top: 0;
		}
		
		@include breakpoint(medium) {
			position:relative;
			border-top: solid 1px _palette(border);
			margin: (_size(section-spacing)*0.1) 0 0 0;
			padding: _size(section-spacing) 0 0 0;
			width: 100%;
		}


	}