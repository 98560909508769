// About page (/about)

.markdown {
  padding-bottom: _size(section-spacing) * 1.5;

  p {
    margin: auto;
  }

  h1 {
    font-size: .8em;
    margin-top: 3em;
  }
}
