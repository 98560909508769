

#logo {
    border-bottom: 0;
    display: inline-block;
    margin: 0 0 _size(element-margin) 0;
    overflow: hidden;
    position: relative;


    img {
        width: 8em;
        height: 8em;
        object-fit: cover;
        border-radius: 50%;
        display: block;
    }
    p {
        text-align: center;
    }
    
    @include breakpoint(small) {
        margin: 0 0 (_size(element-margin) * 0.25) 0;
    }
}