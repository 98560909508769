#nav {

    ul {  
    @include vendor('display', 'flex');
    @include vendor('justify-content', 'space-around');
    @include vendor('gap', '2.5em');

    flex-wrap: nowrap;
    margin: 0;
    padding: 0 0 8px 0;
    li {
    background-color: inherit;
    list-style: none;

   

        a {
        
            box-shadow: none;
            font-size:1.1em;
            text-transform: uppercase;
        
        

            &:hover {
                border-bottom: 1px _palette(accent) solid ;
                border-color:_palette(accent) ;
                color: _palette(accent) !important;
            }
            &.active {
                border-bottom: 1px _palette(accent) solid ;
                border-color:_palette(accent) ;
                color: _palette(accent) !important;
            }

        }
    }
}

   
    

}